//Formularios publicos

.inputgroup2,.inputgroup3{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.inputgroup2 .form-group{
    width:48%;
}
.inputgroup3 .form-group{
    width:30%;
}

#formNuevoSocio{
    width: 50%;
    margin: auto;
    border: solid thin $primary;
    background-color: $primary;
    border-radius: 10px;
    color: white;

    .avatar-group{
        display: flex;
        
        #contenedor-avatar{
            width:50%;
        }

        .avatar-group-botones{
            width:50%;
            display:flex;
            flex-direction:column;
            justify-content:space-around;
        }
    }
}

