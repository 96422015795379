.registro-historial, .registro-lista, .registro-items, .cabecera-items, .cabecera-historial, .cabecera-lista, .registro-historial-items{
    border-bottom: solid thin #1099ad;
    background-color: #fff;
    padding: .3rem;

}



.registro-historial img{
    width: 100%;
}
.registro-historial label{
    font-size: smaller;
    text-align: center;
}

#recibos-lista, #socios-lista, #productos-lista, #productos-lista-documento, #actividades-lista-documento{
    overflow-y: scroll;
    height: 400px;
    border: solid thin #1099ad;
    border-radius: 10px;
}


//Overflow
#recibos-lista::-webkit-scrollbar{
    -webkit-appearance: none;
}
#recibos-lista::-webkit-scrollbar:vertical{
    width:10px;
}
#recibos-lista::-webkit-scrollbar-button:increment,#recibos-lista::-webkit-scrollbar-button{
    display: none;
} 
#recibos-lista::-webkit-scrollbar:horizontal{
    height: 10px;
}
#recibos-lista::-webkit-scrollbar-thumb{
    background-color: #797979;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
}
#recibos-lista::-webkit-scrollbar-track{
    border-radius: 10px;  
}