//Empresas
#empresas-container{
    margin-top: 2rem;
}

#empresas-container .card{
    width: 14rem !important;
    margin-bottom: 1rem;
    margin-right: 2rem;
    border-radius: 10px;
    margin-bottom: 1rem;
    box-shadow: 5px 6px 10px 3px;
    border:solid thin #483948;//#1099ad;
    background-color: #F5F4E5; //#E9F9E6;
}

#empresas-container .card-img-top{
    height: 200px;
}

#empresas-container .card-body:hover{
    cursor: pointer;
}

.tag-info{
    background-color: lightseagreen;
    color: #fff;
    width: fit-content;
    padding: 0.1rem;
    border-radius: 5px;
    margin-right: 0.4rem;
    font-size: small;
    margin-bottom:0.4rem;
}


.tag-actividad{
    background-color: #6120B2;
    color: #fff;
    width: fit-content;
    padding: 0.1rem;
    border-radius: 5px;
    margin-right: 0.4rem;
    font-size: small;
    margin-bottom:0.4rem;
}


//Editor html textarea
.tox-promotion, .tox-statusbar__branding{
    display: none;
}

