//Formularios publicos
@media screen and (max-width: 399px) {
    #formNuevoSocio{
        width: 100%;
    
        .avatar-group{
            flex-direction:column;
            
            #contenedor-avatar{
                width:100%;
                margin-bottom:15px;
            }
    
            .avatar-group-botones{
                width:100%;
                flex-direction:row;
            }
        }
    }
    .container-formulario-publico{
        height: 100%;
    }
}