//Cards
#socios-container{
    margin-top: .5rem;
}

#socios-container .card{
    width: 14rem !important;
    margin-bottom: 1rem;
    margin-right: 2rem;
    border-radius: 10px;
    margin-bottom: 1rem;
    box-shadow: 5px 6px 10px 3px;
    border:solid thin #483948;//#1099ad;
    background-color: $card-bg !important; //#E9F9E6;
}

#socios-container .card-img-top{
    height: 200px;
}
.grey-filter{
    filter: grayscale(1);
}

#socios-container .card-title{
    height: 50px;
}

#socios-container .card-body:hover{
    cursor: pointer;
}


//Tags
.tag-rojo{
    color: red;
    width: fit-content;
    padding: 0.2rem;
    text-align: right;
    margin-right: 0.4rem;
    font-size: 16px;
    //position: absolute;
    //top: 0;
    //right: 0;
    margin-right: 0;
    border-radius: 5px;
}

.tag-verde{
    color: green;
    width: fit-content;
    padding: 0.2rem;
    margin-right: 0.4rem;
    font-size: 16px;
    //position: absolute;
    //top: 0;
    //right: 0;
    margin-right: 0;
    border-radius: 5px;
}

.tag-info{
    background-color: lightseagreen;
    color: #fff;
    width: fit-content;
    padding: 0.1rem;
    border-radius: 5px;
    margin-right: 0.4rem;
    font-size: small;
    margin-bottom:0.4rem;
}


.tag-actividad{
    background-color: #6120B2;
    color: #fff;
    width: fit-content;
    padding: 0.1rem;
    border-radius: 5px;
    margin-right: 0.4rem;
    font-size: small;
}


//Editor html textarea
.tox-promotion, .tox-statusbar__branding{
    display: none;
}

//Pendientes aprobar
.pendientes-aprobar{
    background-color: $primary;
    border: solid thin $primary;
    border-radius: 5px;
    color: white;
}
.socios-pendientes{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 5px 5px 5px 5px;
}
.socio-pendiente{
    background-color: $card-bg;
    color: #000000;
    border-radius: 5px;
    margin:10px;
}


//NUEVA TABLA SOCIOS

.tabla-socios{
    width: 100%;
    border-collapse: collapse;
    margin-top: 2rem;
    background-color: #fff;
    border-radius: 10px;
    border:solid thin black;
    font-size: 12px;
}

.tabla-socios .cabecera-socios{
    display: flex;
    justify-content: space-between;
    border-bottom: solid thin #000;
    font-weight: bolder;
    color: #fff;
    background-color: $primary;
    border-radius: 8px 8px 0 0;
    padding-right: 10px;
}

.tabla-socios .cuerpo-socios{
    display: block;
    height: 590px;
    overflow: auto;
}

.tabla-socios .cuerpo-socios .item-cuerpo-socios{
    display: flex;
    justify-content: space-between;
    border-bottom: solid thin #000;
}

.tabla-socios .nsocio{
    width: 80px;//5rem;
    text-align: center;
    padding: 5px;
}
.tabla-socios .nombre{
    width: 160px;//10rem;
    text-align: center;
    padding: 5px;
    border-left: solid thin silver;
}
.tabla-socios .apellidos{
    width: 240px;//15rem;
    text-align: center;
    padding: 5px;
    border-left: solid thin silver;
}
.tabla-socios .dni{
    width: 125px;//8rem;
    text-align: center;
    padding: 5px;
    border-left: solid thin silver;
}
.tabla-socios .direccion{
    width: 315px;//20rem;
    text-align: center;
    padding: 5px;
    border-left: solid thin silver;
}
.tabla-socios .actividad{
    width: 80px;//5rem;
    text-align: center;
    padding: 5px;
    border-left: solid thin silver;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.tabla-socios .actividad div{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.tabla-socios .estado{
    width: 20px;//2rem;
    text-align: center;
    padding: 5px;
    border-left: solid thin silver;
}
.tabla-socios .acciones{
    width: 150px;//5rem;
    text-align: center;
    padding: 5px;
    border-left: solid thin silver;
}

//::-webkit-scrollbar {
//    display: none;
//}

.btn-tabla{
    font-size:16px;
}
.btn-tabla:hover{
    cursor: pointer;
}

.ordenar-tabla-socios:hover{
    cursor: pointer;
}

