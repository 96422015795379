//Actividades
#actividades-container{
    margin-top: 2rem;
}

#actividades-container .card{
    width: 14rem !important;
    margin-bottom: 1rem;
    margin-right: 2rem;
    border-radius: 10px;
    margin-bottom: 1rem;
    box-shadow: 5px 6px 10px 3px;
    border:solid thin #483948;//#1099ad;
    background-color: #F5F4E5; //#E9F9E6;
}

#actividades-container .card-img-top{
    height: 200px;
}

#actividades-container .card-title{
    height: 50px;
}

#actividades-container .card-body:hover{
    cursor: pointer;
}

//Editor html textarea
.tox-promotion, .tox-statusbar__branding{
    display: none;
}

//Lista pdf socios - actividad
#listado-socios-clase{
    margin-top: 0;
    margin-left: 3rem;
    margin-bottom: 0;
    #cabecera-listado{
        height: 100px;
        margin-top: 10px;
        .logoclub{
            position:absolute;
            top: 15px;
            right: 45px;
        }
        .club{
            position:absolute;
            top: 20px;
            left: 45px;
            font-size: 30px;
        }
        .clase{
            position:absolute;
            top: 60px;
            left: 45px;
            font-size: 20px;
        }
    }
    table, th, td{
        border: solid thin black;
    }
    td{
        //padding-left: 1rem;
    }
    .th-listado-clase{
        background-color: $primary;
        color: #fff;
        text-align: center;
    }
    .linea-listado-socios{
        font-size: 12px !important;
        .listado-clase-mes{
            width:35px;
        }
        
        .listado-clase-nombre{
            width: 240px;
        }
    }    
    .linea-listado-socios:nth-child(odd){
        background-color: $card-bg !important;
    }
    .linea-listado-socios:nth-child(even){
        background-color: #ffffff !important;
    }
}






