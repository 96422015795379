@media screen and (max-width: 399px) {
    //Cards
    #productos-container .card{
        width: 370px !important;
        margin-bottom: 3rem;
        margin-right: 1rem;
        margin-left: 1rem;
    }
    
    #productos-container .card-img-top{
        height: fit-content;
    }
    
    #productos-container .card-title{
        height: 80px;
        font-size: 20px !important;
    }

    #productos-container .card p{
        font-size: medium;
    }

    #productos-container .card i{
        font-size: x-large;
    }

    //Tags
    .tag-rojo, .tag-verde, .tag-info, .tag-actividad{
        font-size: medium;
    }
    
}



