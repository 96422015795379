//Modal recibo

.modal-recibo-body{
    display:flex;
    justify-content:space-around;
}

.modal-recibo-body-part1{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-right:solid thin black;
    width:25%;
    padding:0.8rem;
}

.modal-recibo-body-part2{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width:70%;
    padding:0.5rem;
}

.modal-recibo-body-part2-inputgroup2,.modal-recibo-body-part2-inputgroup4{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}
.modal-recibo-body-part2-inputgroup4 .form-group{
    width:22%;
}
.modal-recibo-body-part2-inputgroup2-concepto{
    width:80%;
}
.modal-recibo-body-part2-inputgroup2-emision{
    width:18%;
    margin-top: 1rem;
}   

#items-recibo{
    margin-top: 10px;
    height: 200px;
    border: solid thin #1099ad;
    background-color: #fff;
    border-radius: 10px;
    overflow-y: scroll;
}

.cabecera-items{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display:flex;
    flex-direction:row;
    align-items: center;
    width: 100%;
}

.registro-items{
    display:flex;
    flex-direction:row;
    align-items: center;
    width: fit-content;
}

.input-item-id, .input-item-nombre, .input-item-iva, .input-item-cantidad, .input-item-pvp, .input-item-descuento, .input-item-importe, .input-item-hasta{
    width:95%;
}

.modal-recibo-footer-botones{
    width:100%;
    display: flex;
    justify-content: space-between;
}
.modal-recibo-footer-botones div{
    width:50%;
}

.cabecera-item-recibo-item{
    width:10%;
}
.cabecera-item-recibo-nombre{
    width:25%;
}
.cabecera-item-recibo-iva{
    width:10%;
}
.cabecera-item-recibo-cantidad{
    width:5%;
}
.cabecera-item-recibo-pvp{
    width:10%;
}
.cabecera-item-recibo-descuento{
    width:10%;
}
.cabecera-item-recibo-importe{
    width:10%;
}
.cabecera-item-recibo-hasta{
    width:15%;
}
.registro-item-recibo-item{
    width:10%;
}
.registro-item-recibo-nombre{
    width:25%;
}
.registro-item-recibo-iva{
    width:10%;
}
.registro-item-recibo-cantidad{
    width:5%;
}
.registro-item-recibo-pvp{
    width:10%;
}
.registro-item-recibo-descuento{
    width:10%;
}
.registro-item-recibo-importe{
    width:10%;
}
.registro-item-recibo-hasta{
    width:15%;
}
.registro-item-recibo-borrar{
    display:flex;
    justify-content:space-around; 
    width:5%;
}