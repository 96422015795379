//Modal recibo
@media screen and (max-width: 399px) {
    .modal-recibo-body{
        display:flex;
        justify-content:space-around;
        flex-direction: column;
    }    
    .modal-recibo-body-part1{
        border-right:none;
        width:100%;
    }
    
    .modal-recibo-body-part2{
        width:100%;
    }
    .modal-recibo-body-part2-inputgroup4 .form-group{
        width:45%;
    }
    .modal-recibo-body-part2-inputgroup2,.modal-recibo-body-part2-inputgroup5{
        flex-direction: column;
    }
    .modal-recibo-body-part2-inputgroup2 .form-group,.modal-recibo-body-part2-inputgroup5 .form-group{
        width:100%;
    }

    #items-recibo{
        width:343px;
        overflow-x: scroll;
    }
    .cabecera-items{
        width: fit-content;
    }
    #recibo{
        display:none;
    }

    #contenedor-avatar{
        margin-left:2.5rem;
    }

    .modal-recibo-footer-botones div{
        width:auto;
    }
    .modal-importe-total{
        height: fit-content;
    }
    .cabecera-item-recibo-item{
        width:225px;
    }
    .cabecera-item-recibo-nombre{
        width:350px;
    }
    .cabecera-item-recibo-iva{
        width:125px;
    }
    .cabecera-item-recibo-cantidad{
        width:125px;
    }
    .cabecera-item-recibo-pvp{
        width:125px;
    }
    .cabecera-item-recibo-descuento{
        width:125px;
    }
    .cabecera-item-recibo-importe{
        width:125px;
    }
    .cabecera-item-recibo-hasta{
        width:125px;
    }
    .cabecera-item-recibo-borrar{
        width:125px;
    }
    .registro-item-recibo-item{
        width:225px;
    }
    .registro-item-recibo-nombre{
        width:350px;
    }
    .registro-item-recibo-iva{
        width:125px;
    }
    .registro-item-recibo-cantidad{
        width:125px;
    }
    .registro-item-recibo-pvp{
        width:125px;
    }
    .registro-item-recibo-descuento{
        width:125px;
    }
    .registro-item-recibo-importe{
        width:125px;
    }
    .registro-item-recibo-hasta{
        width:125px;
    }
    .registro-item-recibo-borrar{
        width:125px;
    }
}