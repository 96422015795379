//Productos
#productos-container{
    margin-top: 2rem;
}

#productos-container .card{
    width: 14rem !important;
    margin-bottom: 1rem;
    margin-right: 2rem;
    border-radius: 10px;
    margin-bottom: 1rem;
    box-shadow: 5px 6px 10px 3px;
    border:solid thin #483948;//#1099ad;
    background-color: #F5F4E5; //#E9F9E6;
}

#productos-container .card-img-top{
    height: 200px;
}

#productos-container .card-title{
    height: 80px;
}

#productos-container .card-body:hover{
    cursor: pointer;
}

//Editor html textarea
.tox-promotion, .tox-statusbar__branding{
    display: none;
}

