//Recibos
#recibos-container{
    margin-top: 2rem;
}

#recibos-container .card{
    width: 14rem !important;
    margin-bottom: 1rem;
    margin-right: 2rem;
    border-radius: 10px;
    margin-bottom: 1rem;
    box-shadow: 5px 6px 10px 3px;
    border:solid thin #483948;//#1099ad;
    background-color: #F5F4E5; //#E9F9E6;
}

#recibos-container .card-img-top{
    height: 200px;
}

#recibos-container .card-title{
    height: 25px;
}

#recibos-container .card-body:hover{
    cursor: pointer;
}

.logo-listado-recibo{
    width:20%;
    position: absolute;
    right: 0;
    top:0;
    height: 45px;
    border-radius: 20px;
}

//Editor html textarea
.tox-promotion, .tox-statusbar__branding{
    display: none;
}

//Contabilidad B
.b{
    display: none;
}
