.usuarios-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
 
    .card{
        width: 14rem !important;
        margin-bottom: 3rem;
        margin-right: 2rem;
        border-radius: 10px;
        box-shadow: 5px 6px 10px 3px;
        border: solid thin #483948;
        background-color: #F5F4E5 !important;
    }
}