main{
    background-color: #766B76;
    font-family: 'Roboto', sans-serif;
    min-height: 85vh 

}

//Barra navegacion
.navbar{
    padding-bottom: 0 !important;
}

.navbar-collapse{
    height: 75px !important;//Revisar para movil
}
.navbar,footer{
    background-color: #483948;//#E9F9E6;//#1099ad;
}

.navbar-nav{
    height: 75px !important;
}

.navbar-aux{
    background-color: #766B76;//#f8fafc !important;
}
.nav-item{
    margin-top: 25px;
}
.nav-link{
    color: #ffffff;//lightseagreen;
    border-radius: 10px;
    font-family: 'Roboto', sans-serif;
    font-weight: bolder;
    font-size: 14px;
}

.nav-activo{
    background-color: $secondary;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    height: 100%;
}

.nav-link:hover{
    background-color: #766B76; //lightseagreen;
}

.navbar-brand img {
    height:65px;
    width:65px;
}

.container-fluid{
    padding-left: 0;
}

footer{
    padding: 1rem;
    color: #ffffff !important;
}

//Botones
.btn-success{
    background-color: #B2AB2E !important;    //lightseagreen !important;
    color: #E8F8F5 !important;
}

.boton{
    background-color: #B2AB2E !important;    //lightseagreen;
    color: #ffffff;
}

.boton:hover{
    background-color: #F5F4E5;
}

//Scrollbar style
/* width */
::-webkit-scrollbar {
    width: 10px;
}
  
/* Track */
::-webkit-scrollbar-track {
background: #f1f1f1;
margin-right: 10px;
}
  
/* Handle */
::-webkit-scrollbar-thumb {
background: #888;
}
  
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
background: #555;
}

//Errores
.cajaError{
    background-color: #483948;
    color: #ffffff;
    width: fit-content;
    border: solid thin #483948;
    border-radius: 0.5rem;
    padding: 0.3rem;
    display: flex;
    flex-wrap: wrap;
    margin: .7rem;
    font-size: smaller;
    align-items: center;
}

.hide-error{
    cursor: pointer;
}

.capitalise
{
    text-transform:capitalize;
}

.alert{
    margin-bottom: 0 !important;
}


.tag-1trimestre{
    font-size: small;
    position: absolute;
    top: 0;
    right: 0;
    padding-left: 3px;
    padding-right: 3px;
    background-color: #1BB10E;
    color: white;
    border-bottom-left-radius: 8px;
    border-top-right-radius: 8px;
}
.tag-2trimestre{
    font-size: small;
    position: absolute;
    top: 0;
    right: 0;
    padding-left: 3px;
    padding-right: 3px;
    background-color: #B1530E;
    color: white;
    border-bottom-left-radius: 8px;
    border-top-right-radius: 8px;
}
.tag-3trimestre{
    font-size: small;
    position: absolute;
    top: 0;
    right: 0;
    padding-left: 3px;
    padding-right: 3px;
    background-color: #A40EB1;
    color: white;
    border-bottom-left-radius: 8px;
    border-top-right-radius: 8px;
}
.tag-4trimestre{
    font-size: small;
    position: absolute;
    top: 0;
    right: 0;
    padding-left: 3px;
    padding-right: 3px;
    background-color: #0E6CB1;
    color: white;
    border-bottom-left-radius: 8px;
    border-top-right-radius: 8px;
}