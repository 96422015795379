//Modal documento
.logo-documento{
    width: 100%;
    height: 100%;
    display: flex;
    border-bottom: solid $primary;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 10px;
    

    img 
    {
        width: 20%;
        filter: brightness(1.1);
        mix-blend-mode: multiply;
    }
    h1{
        width: 80%;
        text-align: right;
        font-size: 60px;
    }
}
.cabecera-documento{
    display:flex;
    flex-direction:row;
    flex-wrap: wrap;
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    padding-top:30px;
    border-bottom: solid $primary;
    padding-bottom: 10px;
    .emisor-documento, .datos-emision-documento{
        width: 50%;
        padding-left: 50px;
    }
    .fecha-documento{
        width: 35%;
        margin-bottom: 10px;
    }
    .num-documento{
        width: 35%;
        margin-bottom: 10px;
    }
    .socio-id{
        width: 35%;
        text-align: end;
    }
}

.cliente-documento{
    width: 100%;
    padding-left: 80px;
    padding-right: 30px;
    padding-top:30px;
} 

.items-documento{
    margin-top: 30px;
    margin-left: 30px;
    margin-right: 30px;
    height: 200px;
    border: solid thin $primary;
    background-color: #fff;
    border-radius: 10px;
    overflow-y: scroll;
    .cabecera-items{
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        display:flex;
        flex-direction:row;
        align-items: center;
        width: 100%;
        .cabecera-item-documento-cantidad{
            width:10%;
        }
        .cabecera-item-documento-nombre{
            width:45%;
        }
        .cabecera-item-documento-precio{
            width:15%;
        }
        .cabecera-item-documento-descuento{
            width:15%;
        }
        .cabecera-item-documento-importe{
            width:15%;
        }
        .cabecera-item-documento-borrar{
            display:flex;
            justify-content:space-around; 
            width:5%;
        }
    }
    .registro-items{
        display:flex;
        flex-direction:row;
        align-items: center;
        width: fit-content;
        .registro-item-documento-cantidad{
            width:10%;
        }
        .registro-item-documento-nombre{
            width:45%;
        }
        .registro-item-documento-precio{
            width:15%;
        }
        .registro-item-documento-descuento{
            width:15%;
        }
        .registro-item-documento-importe{
            width:15%;
        }
        .registro-item-documento-borrar{
            display:flex;
            justify-content:space-around; 
            width:5%;
        }
    }
}
.footer-items-documento{
    display:flex;
    justify-content:space-between;
    padding-right: 30px;
    padding-left:30px;
}



.input-item-id, .input-item-nombre, .input-item-iva, .input-item-cantidad, .input-item-pvp, .input-item-descuento, .input-item-importe, .input-item-hasta{
    width:95%;
}

.modal-recibo-footer-botones{
    width:100%;
    display: flex;
    justify-content: space-between;
}
.modal-recibo-footer-botones div{
    width:50%;
}



