#horario_actividad_id{
    width:20%;
}
#horario_dia{
    width:20%;
}
#horario_hora_inicio{
    width:20%;
}
#horario_hora_fin{
    width:20%;
}
#guardar_horario{
    width:10%;
}

.horario{
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    text-align: center;
    color: white;
 
    .linea-horario{
        display:flex;
        flex-direction:row;
        width:100%; 
    }

    .cajaFija{
        height: 50px;
        width: 12.5%;
        border: 1px solid $secondary;
        background-color: $primary;
        display: flex;
        justify-content: center;
        align-items: center;
        h5{
            margin:0;
        }
    }
    .cajaSala{
        height: 50px;
        width: 12.5%;
        border: 1px solid $primary;
        background-color: $secondary;
        display: flex;
        justify-content: center;
        align-items: center;
        h5{
            margin:0;
        }
    }
    .cajaActividad{
        height: 50px;
        width: 12.5%;
        border: 1px solid $secondary;
        background-color: #ffffff;
        display: flex;
        justify-content: space-around;
        align-items: center;
        h5{
            margin:0;
        }
    }
    .cajaActividad:hover{
        cursor: pointer;
        border: 2px solid $primary;
    }
    
}