// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

//Layoute
@import 'layout';
@import 'layout-mobile';
@import 'choices';

//Home
@import 'home';

//Socios
@import 'socios';
@import 'socios-mobile';
@import 'modal-socio';
@import 'modal-socio-mobile';

//Empresas
@import 'empresas';
@import 'empresas-mobile';
@import 'modal-empresa';
@import 'modal-empresa-mobile';

//Actividades
@import 'actividades';
@import 'actividades-mobile';

//Productos
@import 'productos';
@import 'productos-mobile';

//Recibos
@import 'recibos';
@import 'recibos-mobile';
@import 'modal-recibo';
@import 'modal-recibo-mobile';

//Historiales
@import 'historiales';

//Modales
@import 'modales';

//Tablas
@import 'tablas';

//Avisos
@import 'etiquetas';

//Informes
@import 'informes';

//Login
@import 'login';
@import 'login_mobile';

//Publico
@import 'publico';
@import 'publico-mobile';

//Usuarios
@import 'usuarios';
@import 'usuarios-mobile';

//Horario
@import 'horario';

//Facturas
@import 'facturas';
@import 'facturas-mobile';
@import 'modal-documento';
@import 'modal-documento-mobile';




//Forms
.custom-form{
    background: linear-gradient(-45deg, #4cd137,#A6E89B,#4cd137);
    border-radius: 20px;
    border: solid thin #374CD1;
    color:#FFF;    
}

//Recibo pdf

.label-pdf-recibo{
    font-weight: bold;
    font-size: small;
}
.dato-pdf-recibo{
    font-size: smaller;
}

