@media screen and (max-width: 399px) {
    .login {
        position : static;
        margin-top: 100px;
        display:flex;
        justify-content: center;
    }
    .image-login{
        img {
            object-fit: cover;
        }
    }
}